import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Umkehrhypothek = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader
                h1Title="Schon einmal von der Umkehrhypothek gehört? Hier erfährst du, worum es geht"
                showCalc={false}
            />
            <Article>
                <p>
                    Die Umkehrhypothek ist leider nicht der Umkehrschluss einer{" "}
                    <Link to="/artikel/hypothekenkredit/" target="_blank" rel="noreferrer noopener">
                        Hypothek
                    </Link>
                    , in der die Bank auf jegliche Sicherheiten verzichtet. Es handelt sich dabei um eine spezielle Form
                    des Kredits, die vor allem für Pensionist*innen oder ältere Menschen ohne Erben interessant sein
                    kann. In Österreich und Deutschland ist sie kaum bekannt und ziemlich unüblich, in den USA jedoch
                    schon länger Gang und Gäbe. Hier erfährst du, wie eine Umkehrhypothek funktioniert.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Umkehrhypothek?</h2>
                <p>
                    Bei der Umkehrhypothek erhält man einen{" "}
                    <Link to="/dienstleistungen/kreditvergleich/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    für eine bereits abbezahlte Immobilie von der Bank, die nach dem Tod in ihren Besitz übertragen
                    wird. Die Bank verkauft danach die Immobilie weiter und erhält so ihr Geld für den ausbezahlten
                    Kredit zurück. Die Umkehrhypothek ist ein zinsfreier Kredit, der in einer einmaligen, einer
                    jährlichen oder als monatliche Zahlung ausgeschüttet werden kann. Sei eignet sich also, um die
                    Pension aufzubessern, oder teure Finanzierungen unterschiedlicher Bedürfnisse zu ermöglichen.
                    Sinnvoll ist ein solcher Kredit jedoch nur, wenn die Immobilie bereits schuldenfrei ist und es keine
                    Erben gibt, die Anspruch auf.
                </p>
                <p>
                    Oft hat man in seinem Pensionsalter seinen Kredit längst abbezahlt und kann mietfrei in der eigenen
                    Immobilie leben, in der man auch bleiben möchte. Aber wenn die Pension trotzdem so niedrig ist, dass
                    kaum etwas zum leben bleibt, ist es schade, dass das gesamte Vermögen bereits in der Immobilie
                    steckt. Wenn man sie nicht verkaufen möchte, gibt die Umkehrhypothek einem die Möglichkeit, dort bis
                    zum Ende seines Lebens zu wohnen, aber trotzdem Geld dafür zu bekommen. Reich wird man damit aber
                    nicht, da die Banken Immobiliengewinn geringer ansetzt, als beim klassischen{" "}
                    <Link to="/artikel/steuern-beim-immobilienkauf/" target="_blank" rel="noreferrer noopener">
                        Immobilienkauf
                    </Link>
                    .
                </p>
                <hr />

                <h2>Wie bekomme ich eine Umkehrhypothek?</h2>
                <p>
                    In Österreich ist es recht schwierig Anbieter für eine Umkehrhypothek zu finden, weil der Gedanke in
                    unserer Gesellschaft tief verankert ist, dass die Nachfahren eine Erbschaft erhalten sollen. Aber
                    nicht jede Person hat{" "}
                    <Link to="/artikel/schulden-erben/" target="_blank" rel="noreferrer noopener">
                        Erben
                    </Link>
                    . Wenn du Erben hast, solltest du vor Vertragsabschluss auf jeden Fall mit ihnen sprechen. Eine
                    Umkehrhypothek ist nämlich nicht leicht wieder umzukehren, man kann den bindenden Vertrag eigentlich
                    nicht wieder zurückziehen. Wenn du allerdings keine Erben hast und die Idee ansprechend findest,
                    musst du dich vorher sehr gut informieren und beraten lassen. Denn es gibt nicht viele Anbieter in
                    Österreich, die eine Umkehrhypothek gewähren. Außerdem gibt es andere Alternativen, die du
                    vielleicht nicht auf dem Schirm hast. Da einige von ihnen in Österreich üblicher und einfacher zu
                    erhalten sind, möchten wir sie in diesem Artikel auch nennen. Jede Situation ist anders und man muss
                    vor jedem Kredit gut überlegen, ob er der richtige für einen ist.
                </p>
                <hr />

                <h2>Ist diese Kreditform sinnvoll?</h2>
                <p>
                    In den USA ist sie zwar längst ein attraktives Modell zur Altersvorsorge, doch das heißt nicht, dass
                    sie für jede Person das richtige Modell ist. Die Umkehrhypothek lohnt sich, da sie steuer- und
                    zinsfrei ist. Besitzt du also eine Immobilie, die in einem guten Zustand ist und in guter Lage liegt
                    – sprich, die man weiterverkaufen kann – und du nicht vorhast, sie zu vererben, kann die
                    Umkehrhypothek für dich durchaus für eine Art zusätzliches Einkommen sorgen. Aber da Banken bei der
                    Umkehrhypothek eine Immobilie oft mit größeren Abschlägen bewertet, lohnt es sich manchmal eher,
                    seine Wohnsituation zu überdenken und die eigene Immobilie zu verkaufen. Man kann mit dem Geld
                    vielleicht eine kleinere Immobilie{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        finanzieren
                    </Link>{" "}
                    und es bleibt trotzdem noch etwas Geld übrig. Aber es gibt auch andere Alternativen.
                </p>
                <hr />

                <h2>Welche Alternativen gibt es zur Umkehrhypothek?</h2>
                <p>
                    Die sinnvollste Alternative zur Umkehrhypothek kann also der Verkauf der eigenen Immobilie sein.
                    Durch den Erlös kann die Rente erheblich aufgebessert werden und man kann auf eine kleinere Wohnung
                    umstellen, in der man gut und altersgerecht leben kann. Unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    kann dabei helfen, den Kredit für eine kleinere Immobilie schnell und einfach einzuschätzen.
                </p>
                <p>
                    Wenn der Verkauf wirklich gar keine Option ist, gibt es auch die Möglichkeit zum Teilverkauf. Man
                    verkauft hier bis zu 50% der eigenen Immobilie und erhält dafür eine Sofortzahlung. Als Eigentümer*
                    kann man in diesem Fall weiterhin in seinem Eigenheim wohnen. Man bleibt auch weiterhin als
                    Haupteigentümer*in im Grundbuch eingetragen und kann die Immobilie frei weiterverkaufen,
                    zurückkaufen oder auch vererben.
                </p>
                <hr />

                <h2>Weitere Alternativen zum Teilverkauf</h2>
                <p>
                    Es gibt auch die Möglichkeit der Leibrente, bei der die Immobilie direkt an den Käufer oder die
                    Käuferin übergeht. Der bisherige Eigentümer oder Eigentümerin zieht meistens um und erhält von der
                    kaufenden Person monatliche Rentenzahlungen bis an sein Lebensende. Doch die Leibrente ist für beide
                    Parteien ziemlich riskant, weil die Lebensdauer der verkaufenden Person immer unberechenbar ist.
                    Meistens wird deshalb ein etwas kleinerer Immobilienpreis festgelegt, als beim klassischen Verkauf.
                    Die Leibrente ist übrigens kein Kredit. Man zahlt weder Tilgungsgebühren noch Zinsen. Die
                    Instandhaltung Deiner Immobilie bezahlt die Käuferin oder der Käufer.
                </p>
                <hr />

                <h2>Informiere dich über Immobilienrente und Seniorenkredite</h2>
                <p>
                    Kennen solltest du auch die klassische Immobilienrente, bei der die Immobilie mit einem lebenslangen
                    Wohnrecht verkauft und der Kaufpreis ratenweise ausgezahlt wird. Anders als bei der Umkehrhypothek
                    geht der Immobilienbesitz direkt nach Abschluss der Zahlung auf den Käufer über und nicht erst nach
                    Ableben des vorherigen Eigentümers oder der Eigentümerin. Banken bieten außerdem zahlreiche
                    sogenannte „Seniorenkredite“ an, über die man sich bei seiner Bank informieren kann. Als
                    Kreditnehmer*in zahlt man die Sollzinsen für den Kredit ab. Für diese Kredite können entweder die
                    Erben in Bürgschaft gehen, oder die Immobilie wird später verkauft, um den Kredit abzubezahlen.{" "}
                </p>
                <p>
                    Es lohnt sich, über diese Alternativen nachzudenken, da für den Kreditvertrag einer Umkehrhypothek
                    hohe Sollzinsen und Gebühren anfallen können. Außerdem erhält man weniger Geld für seine Immobilie
                    und sie kann nicht mehr als Sicherheit für andere Zwecke genutzt werden. Wenn man Erben hat, wird
                    das Erbe dadurch natürlich ziemlich stark reduziert.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"umkehrhypothek"}></BreadcrumbList>
            <ArticleStructuredData
                page={"umkehrhypothek"}
                heading={"Schon einmal von der Umkehrhypothek gehört? Hier erfährst du, worum es geht"}
            />
        </Layout>
    );
};

export default Umkehrhypothek;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.umkehrhypothek", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
